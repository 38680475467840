import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import LenseCards from 'components/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/LenseCards';
import Video5905Lense from 'components/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/Video5905Lense';
import NavButtons from 'components/Outdoor_Cameras/IN-5905_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-5905 HD Manual Lense Adjustment",
  "path": "/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-5905 HD Indoor IP Camera Manual Lense Adjustment",
  "image": "./P_SearchThumb_IN-5905HD_Lense_adjust.png",
  "social": "/images/Search/P_SearchThumb_IN-5905HD_Lense_adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-5905HD_white.webp",
  "chapter": "Outdoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-5905 HD Manual Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-5905 HD Indoor IP Camera Manual Lense Adjustment' image='/images/Search/P_SearchThumb_IN-5905HD_Lense_adjust.png' twitter='/images/Search/P_SearchThumb_IN-5905HD_Lense_adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Aussenkameras/IN-5905_HD/Objektivjustage/' locationFR='/fr/Outdoor_Cameras/IN-5905_HD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-5905-hd-manual-lense-adjustment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-5905-hd-manual-lense-adjustment",
        "aria-label": "in 5905 hd manual lense adjustment permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-5905 HD Manual Lense Adjustment`}</h2>
    <p>{`When you receive your camera, we already adjusted the focus for the camera to be operational in a general surveillance scenario. For your IN-5905 HD this means, that they give you a sharp image for distances between 5 and 10m. If you need a different focus distance, just turn the camera lense clockwise or counter clockwise, until you have your scene in focus.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <LenseCards mdxType="LenseCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`VII.`}</strong>{` After you obtained the right focal, please carefully put everything back together by following this guide in reverse. When inserting the lens into the front case, please pay attention to the inner cables. Make sure they don’t obstruct the view and avoid squeezing any cables.`}</p>
    <Video5905Lense mdxType="Video5905Lense" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      